import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from './events/Store'

import Http from './events/Http'
import Config from './events/Config'

import Common from './common'

import Login from './components/Login'
import Register from './components/Register'

/** Profile **/
import Profile from './components/Profile'

/** Campaigns **/
import CampaignsItems from './components/campaigns/Items'
import CampaignsItem from './components/campaigns/Item'
import CampaignsCreate from './components/campaigns/Create'

/** Statistics **/
import StatisticsItems from './components/statistics/Items'

/** Logs **/
import LogsItems from './components/logs/Items'

/** Payments **/
import PaymentsItems from './components/payments/Items'

/** Contacts **/
import Contacts from './components/Contacts'



Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: '',
    linkExactActiveClass: '',
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
            meta: {
                auth: 'guest'
            }
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
            meta: {
                auth: 'guest'
            }
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/campaigns',
            name: 'CampaignsItems',
            component: CampaignsItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/campaigns/edit/:id',
            name: 'CampaignsItem',
            component: CampaignsItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/campaigns/create',
            name: 'CampaignsCreate',
            component: CampaignsCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/statistics',
            name: 'StatisticsItems',
            component: StatisticsItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/logs',
            name: 'LogsItems',
            component: LogsItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/payments',
            name: 'PaymentsItems',
            component: PaymentsItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/contacts',
            name: 'Contacts',
            component: Contacts,
            meta: {
                auth: 'auth'
            }
        },
    ]
})

router.beforeEach(async (to, from, next) => {

    var user = Store.state.user

    if(!('auth' in user)) {
        await Http.post(`${Config.ApplicationAPI}/auth/check`)
        .then((response) => {
            response = response.data
            switch (response.status) {
                case 'error':
                    console.log('check auth error')
                    break
                case 'success':
                    document.body.classList.remove('overflow-hidden')
                    Store.commit('setLoaded', true)
                    Store.commit('setUser', response.user)
                    break
                default:
                    console.log('Unknown error')
            }
        }).catch((error) => {
            document.body.classList.remove('overflow-hidden')
            Store.commit('setLoaded', true)
        })

        user = Store.state.user
    }

    if((!('auth' in user) || !user.auth) && to.meta.auth == 'auth' && from.meta.auth != to.meta.auth) {
        router.push({name: 'Login'})
    } else if((('auth' in user) && user.auth) && to.meta.auth == 'guest' && from.meta.auth != to.meta.auth) {
        router.push({name: 'CampaignsItems'})
    }

    next()

})


export default router