<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
  @import "./assets/css/theme.min.css";
  @import "./assets/css/font-awesome.css";
</style>

<script>
export default {
  name: 'App'
}
</script>
