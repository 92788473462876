<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <form class="mb-9" v-on:submit.prevent="createItem">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Создать Компанию</h2>
                        </div>
                    </div>
                    <div class="row g-5">
                        <div class="col-xl-12">
                            <div class="row g-3">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.name"
                                            @input="errors.name = null"
                                            :class="{'is-invalid': errors.name}"
                                            placeholder="Название"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Название</label>
                                        <div class="invalid-feedback">{{errors.name}}</div>
                                    </div>
                                </div>
                                
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            @input="errors.type = null"
                                            v-model="data.type"
                                            :options="utils.types" 
                                            placeholder="Тип компании"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Тип компании</label>
                                        <div class="invalid-feedback">{{errors.type}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-12">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.postback"
                                            @input="errors.postback = null"
                                            :class="{'is-invalid': errors.postback}"
                                            placeholder="Postback"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Postback</label>

                                        <span 
                                            @click="togglePostbackMacro('{CLICK_ID}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{CLICK_ID}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{CLICK_ID}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {CLICK_ID}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{ACTION}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{ACTION}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{ACTION}')
                                            }"
                                            v-tippy="{arrow : true, arrowType: 'round'}"
                                            :content="`registration, deposit, withdraw`"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {ACTION}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{AMOUNT}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{AMOUNT}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{AMOUNT}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {AMOUNT}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{REVENUE}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{REVENUE}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{REVENUE}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {REVENUE}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{IS_FD}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{IS_FD}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{IS_FD}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {IS_FD}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{IS_RD}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{IS_RD}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{IS_RD}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {IS_RD}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{IP}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{IP}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{IP}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {IP}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{COUNTRY}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{COUNTRY}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{COUNTRY}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {COUNTRY}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{CITY}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{CITY}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{CITY}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {CITY}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{DEVICE}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{DEVICE}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{DEVICE}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {DEVICE}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{BROWSER}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{BROWSER}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{BROWSER}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {BROWSER}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{BROWSER_VERSION}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{BROWSER_VERSION}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{BROWSER_VERSION}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {BROWSER_VERSION}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{PLATFORM}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{PLATFORM}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{PLATFORM}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {PLATFORM}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{PLATFORM_VERSION}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{PLATFORM_VERSION}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{PLATFORM_VERSION}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {PLATFORM_VERSION}
                                        </span>

                                        <div class="invalid-feedback">{{errors.postback}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.traffic_source"
                                            @input="errors.traffic_source = null"
                                            :class="{'is-invalid': errors.traffic_source}"
                                            placeholder="Источник трафика"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Источник трафика</label>
                                        <div class="invalid-feedback">{{errors.traffic_source}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <textarea
                                            v-model="data.comment"
                                            @input="errors.comment = null"
                                            :class="{'is-invalid': errors.comment}"
                                            placeholder="Доп. Информация"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Доп. Информация</label>
                                        <div class="invalid-feedback">{{errors.comment}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-3 col-md-3">
                                    <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                        <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                        <span>Создать</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            btn_preloader: false,
            data: {
                name: null,
                type: {
                    name: 'Revshare',
                    key: 1
                },
                postback: null,
                traffic_source: null,
                comment: null
                
            },
            errors: {
                name: false,
                type: false,
                postback: false,
                traffic_source: false,
                comment: false
            },
            utils: {
                types: [
                    {
                        name: 'Revshare',
                        key: 1
                    },
                    {
                        name: 'CPA',
                        key: 2
                    },
                    {
                        name: 'Персональный Промокод',
                        key: 3
                    }
                ],
                macros: {
                    click_id: '{CLICK_ID}',
                    action: '{ACTION}',
                    amount: '{AMOUNT}',
                    revenue: '{REVENUE}',
                    ip: '{IP}',
                    country: '{COUNTRY}',
                    city: '{CITY}',
                    device: '{DEVICE}',
                    browser: '{BROWSER}',
                    browser_version: '{BROWSER_VERSION}',
                    platform: '{PLATFORM}',
                    platform_version: '{PLATFORM_VERSION}',
                    is_fd: '{IS_FD}',
                    is_rd: '{IS_RD}'
                }
            }
        }
    },
    methods: {
        createItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(!this.data.type) {
                this.errors.type = 'Это поле обязательно'
                return
            }
            if(this.data.postback) {
                if(!this.isValidUrl(this.data.postback)) {
                    this.errors.postback = 'Недействительный Postback URL'
                    return
                }
            }
            if(!this.data.traffic_source) {
                this.errors.traffic_source = 'Это поле обязательно'
                return
            }
            if(!this.data.comment) {
                this.errors.comment = 'Это поле обязательно'
                return
            }
            
            this.btn_preloader = true
            this.$axios.post('/campaigns/create', JSON.stringify({
                name: this.data.name,
                type: this.data.type.key,
                postback: this.data.postback,
                traffic_source: this.data.traffic_source,
                comment: this.data.comment
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$router.push({name: 'CampaignsItems'})
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    }
                }
            })
        },
        togglePostbackMacro (item) {
            if(this.data.postback && this.isValidUrl(this.data.postback)) {
                const parseUrl = new URL(this.data.postback)
                
                var params = new URLSearchParams(parseUrl.search.substring(parseUrl.search.indexOf('?')))

                if(this.data.postback.includes(item)) {
                    for(var target_item in this.utils.macros) {
                        if(this.utils.macros[target_item] == item) {
                            params.delete(target_item)
                            break
                        }
                    }
                } else {
                    for(var target_item in this.utils.macros) {
                        if(this.utils.macros[target_item] == item) {
                            params.append(target_item, item)
                            break
                        }
                    }
                }

                var url = `${parseUrl.protocol}//${parseUrl.hostname}`

                if(parseUrl.port) {
                    url += ':' + parseUrl.port
                }

                if(parseUrl.pathname) {
                    url += parseUrl.pathname
                }


                if(params.size) {
                    var query = params.toString()
                    query = query.replace(/%7B/g, '{')
                    query = query.replace(/%7D/g, '}')

                    this.data.postback = `${url}?${query}`
                } else {
                    this.data.postback = url
                }
            } else {
                this.errors.postback = 'Недействительный Postback URL'
            }
        },
        isValidUrl (string) {
            try {
                new URL(string)
            } catch (_) {
                return false
            }

            return true
        }
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
