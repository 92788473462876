<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <h2 class="text-bold text-1100 mb-5">
                    Логи активности
                    <span class="fw-normal text-700 ms-2">({{items_count}})</span>
                </h2>

                <div class="row align-items-center justify-content-between g-3 mb-3" v-show="!preloader">
                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.campaign"
                                :options="utils.campaigns" 
                                placeholder="Компания"
                                label="name">
                                <template v-slot:option="option">
                                    <span>{{ option.name }}</span>
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching && search.length >= 3">
                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>Введите не менее 3 символов</span>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.type"
                                :options="utils.types" 
                                placeholder="Тип"
                                label="name">
                                <template v-slot:option="option">
                                    <span>{{ option.name }}</span>
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching && search.length >= 3">
                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>Введите не менее 3 символов</span>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.merchant"
                                :options="utils.merchants" 
                                placeholder="Валюта"
                                label="currency">
                                <template v-slot:option="option">
                                    <span>{{ option.currency }}</span>
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching && search.length >= 3">
                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>Введите не менее 3 символов</span>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <date-range-picker
                                @update="changeSearch"
                                :locale-data="datepiker"
                                :opens="pikerparams.opens"
                                :always-show-calendars="pikerparams.showcalendars"
                                :close-on-esc="pikerparams.closeonesc"
                                :auto-apply="pikerparams.autoapply"
                                :time-picker="pikerparams.timePicker"
                                :time-picker24-hour="pikerparams.timePicker24Hour"
                                v-model="search.date"
                                class="daterange-picker-medium">
                                <template #ranges="ranges">
                                  <div class="ranges">
                                    <ul>
                                      <li 
                                        v-for="(range, name, key) in pikerranges" 
                                        :key="name" 
                                        @click="ranges.clickRange(range, key)">
                                            {{name}}
                                      </li>
                                    </ul>
                                  </div>
                                </template>
                            </date-range-picker>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <input
                                @input="changeSearch"
                                v-model="search.ip"
                                type="text" 
                                placeholder="IP адрес" 
                                class="form-control form-icon-input">
                            <span class="fa-regular fa-magnifying-glass text-500 fs--1 form-icon"></span>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <input
                                @input="changeSearch"
                                v-model="search.city"
                                type="text" 
                                placeholder="Город" 
                                class="form-control form-icon-input">
                            <span class="fa-regular fa-magnifying-glass text-500 fs--1 form-icon"></span>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.country"
                                :options="utils.countries"
                                placeholder="Страна"
                                label="name">
                                <template v-slot:option="option">
                                    <span>{{ option.name }}</span>
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching && search.length >= 3">
                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>Введите не менее 3 символов</span>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <input
                                @input="changeSearch"
                                v-model="search.browser"
                                type="text" 
                                placeholder="Браузер" 
                                class="form-control form-icon-input">
                            <span class="fa-regular fa-magnifying-glass text-500 fs--1 form-icon"></span>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <input
                                @input="changeSearch"
                                v-model="search.device"
                                type="text" 
                                placeholder="Дивайс" 
                                class="form-control form-icon-input">
                            <span class="fa-regular fa-magnifying-glass text-500 fs--1 form-icon"></span>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <input
                                @input="changeSearch"
                                v-model="search.platform"
                                type="text" 
                                placeholder="Платформа" 
                                class="form-control form-icon-input">
                            <span class="fa-regular fa-magnifying-glass text-500 fs--1 form-icon"></span>
                        </div>
                    </div>
                </div>

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>

                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-show="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1">
                    
                        <div class="table table-responsive-small fs--1">
                            <datatable
                                :filter="search.search_change"
                                :columns="columns" 
                                :data="getItems"
                                :per-page="pager.per_page">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td><router-link :to="{name: 'CampaignsItem', params: {id: row.campaign_id}}">{{ row.campaign_name }}</router-link></td>
                                        <td>{{ row.referral_id }}</td>
                                        <td>{{ utils.types.find(el => el.key == row.type).name }}</td>
                                        <td>{{ row.currency }}</td>
                                        <td>{{ row.amount }}</td>
                                        <td>{{ row.revenue }}</td>
                                        <td>{{ row.geo.ip }}</td>
                                        <td>{{ utils.countries.find(el => el.key == row.geo.country) ? utils.countries.find(el => el.key == row.geo.country).name : null }}</td>
                                        <td>{{ row.geo.city }}</td>
                                        <td>{{ row.agent.platform.name }}</td>
                                        <td>{{ row.agent.device }}</td>
                                        <td>{{ row.agent.browser.name }}</td>
                                        <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>

                        <div class="col-auto d-flex">
                            <ul class="mb-3 pagination">
                                <datatable-pager v-model="pager.page" type="abbreviated"></datatable-pager>
                            </ul>
                        </div>

                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            preloader: true,
            pager: {
                per_page: 25,
                page: 0
            },
            items_count: 0,
            search: {
                search_change: null,
                campaign: null,
                type: null,
                merchant: null,
                ip: null,
                city: null,
                country: null,
                browser: null,
                device: null,
                platform: null,
                date: {
                    startDate: this.$moment().set({date: this.$moment().get('date') - 30}).format('Y-MM-DD HH:mm:ss'),
                    endDate: this.$moment().format('Y-MM-DD HH:mm:ss')
                }
            },
            utils: {
                merchants: [],
                campaigns: [],
                countries: [],
                types: [
                    {
                        name: 'Клик',
                        key: 1
                    },
                    {
                        name: 'Регистрация',
                        key: 2
                    },
                    {
                        name: 'Депозит',
                        key: 3
                    },
                    {
                        name: 'Повторный депозит',
                        key: 5
                    },
                    {
                        name: 'Выплата',
                        key: 4
                    }
                ]
            },
            columns: [
                {label: 'Компания'},
                {label: 'Реферал'},
                {label: 'Тип'},
                {label: 'Валюта'},
                {label: 'Сумма'},
                {label: 'Прибыль'},
                {label: 'IP-адрес'},
                {label: 'Страна'},
                {label: 'Город'},
                {label: 'Платформа'},
                {label: 'Девайс'},
                {label: 'Браузер'},
                {label: 'Дата', field: 'created_at', sortable: true},
            ],
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: true,
                closeonesc: true,
                autoapply: false,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'left'
            },
            pikerranges: {
                'Один день': [this.$moment().set({date: this.$moment().get('date') - 1}).toDate(), this.$moment().toDate()],
                'Одна неделя': [this.$moment().set({date: this.$moment().get('date') - 7}).toDate(), this.$moment().toDate()],
                'Один месяц': [this.$moment().set({date: this.$moment().get('date') - 30}).toDate(), this.$moment().toDate()],
                'Два месяца': [this.$moment().set({date: this.$moment().get('date') - 60}).toDate(), this.$moment().toDate()],
                'Три месяца': [this.$moment().set({date: this.$moment().get('date') - 90}).toDate(), this.$moment().toDate()],
                'Пол года': [this.$moment().set({date: this.$moment().get('date') - 180}).toDate(), this.$moment().toDate()],
                'Один год': [this.$moment().set({date: this.$moment().get('date') - 365}).toDate(), this.$moment().toDate()]
            }
        }
    },
    methods: {
        async getItems ({ sortBy, sortDir, perPage, page }) {
            this.preloader = true
            var items = await this.$axios.post('/logs/items', JSON.stringify({
                pager: {
                    per_page: this.pager.per_page,
                    page: page - 1
                },
                sort: {
                    by: sortBy,
                    dir: sortDir
                },
                search: {
                    campaign: this.search.campaign ? this.search.campaign.id : null,
                    type: this.search.type ? this.search.type.key : null,
                    merchant: this.search.merchant ? this.search.merchant.currency : null,
                    ip: this.search.ip ? this.search.ip : null,
                    city: this.search.city ? this.search.city : null,
                    country: this.search.country ? this.search.country.key : null,
                    browser: this.search.browser ? this.search.browser : null,
                    device: this.search.device ? this.search.device : null,
                    platform: this.search.platform ? this.search.platform : null,
                    date: {
                        start: this.$moment(this.search.date.startDate).format('YYYY-MM-DD H:mm:ss'),
                        expire: this.$moment(this.search.date.endDate).format('YYYY-MM-DD H:mm:ss')
                    }
                }
            }))
            .then((response) => {
                this.preloader = false
                this.setUser(response.data.user)
                return response.data
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })

            if(items && items.status == 'success') {
                this.items_count = items.data.count
                return {
                    rows: items.data.items,
                    totalRowCount: items.data.count
                }
            }

            return {
                rows: [],
                totalRowCount: 0
            }
        },
        async getCountries () {
            await this.$axios.post('/utils/countries/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        for(let item in response.data) {
                            this.utils.countries.push({
                                name: response.data[item],
                                key: item
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        getCampaigns () {
            this.$axios.post('/campaigns/items', JSON.stringify({
                search: {
                    name: null,
                    type: null,
                    status: null
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        for(let item of response.data.items) {
                            this.utils.campaigns.push({
                                name: item.name,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.setUser(response.user)
                
                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        getMerchants () {
            this.$axios.post(`/merchants/items`)
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.utils.merchants = response.data

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'UsersStatusItems'})
                }
            })
        },
        changeSearch () {
            this.search.search_change = String(Date.now())
        }
    },
    async beforeMount () {
        await this.getCountries()
        this.getCampaigns()
        this.getMerchants()
    }
}
</script>
