<template>
	<nav class="navbar navbar-vertical navbar-expand-lg">
	    <div id="navbarVerticalCollapse" class="collapse navbar-collapse">
	        <div class="navbar-vertical-content">
	        	<ul id="navbarVerticalNav" class="navbar-nav flex-column">

	        		<div class="nav-item-wrapper">
					    <router-link 
					    	class="nav-link label-1" 
					    	:to="{name: 'Profile'}" 
					    	:class="{'active': ['Profile'].includes(this.$route.name)}">
					        <div class="d-flex align-items-center">
					            <span class="nav-link-icon"><span class="fa-regular fa-user text-700 fs--1 form-icon"></span></span>
					            <span class="nav-link-text-wrapper"><span class="nav-link-text">Профиль</span></span>
					        </div>
					    </router-link>
					</div>

					<hr/>

					<div class="nav-item-wrapper">
					    <router-link 
					    	class="nav-link label-1" 
					    	:to="{name: 'CampaignsItems'}" 
					    	:class="{'active': ['CampaignsItems', 'CampaignsItem', 'CampaignsCreate'].includes(this.$route.name)}">
					        <div class="d-flex align-items-center">
					            <span class="nav-link-icon"><span class="fa-regular fa-book text-700 fs--1 form-icon"></span></span>
					            <span class="nav-link-text-wrapper"><span class="nav-link-text">Компании</span></span>
					        </div>
					    </router-link>
					</div>

					<hr/>

					<div class="nav-item-wrapper">
					    <router-link 
					    	class="nav-link label-1" 
					    	:to="{name: 'StatisticsItems'}" 
					    	:class="{'active': ['StatisticsItems'].includes(this.$route.name)}">
					        <div class="d-flex align-items-center">
					            <span class="nav-link-icon"><span class="fa-regular fa-chart-simple text-700 fs--1 form-icon"></span></span>
					            <span class="nav-link-text-wrapper"><span class="nav-link-text">Статистика</span></span>
					        </div>
					    </router-link>
					</div>

					<hr/>

					<div class="nav-item-wrapper">
					    <router-link 
					    	class="nav-link label-1" 
					    	:to="{name: 'LogsItems'}" 
					    	:class="{'active': ['LogsItems'].includes(this.$route.name)}">
					        <div class="d-flex align-items-center">
					            <span class="nav-link-icon"><span class="fa-solid fa-clock-rotate-left text-700 fs--1 form-icon"></span></span>
					            <span class="nav-link-text-wrapper"><span class="nav-link-text">Логи</span></span>
					        </div>
					    </router-link>
					</div>

					<hr/>

					<div class="nav-item-wrapper">
					    <router-link 
					    	class="nav-link label-1" 
					    	:to="{name: 'PaymentsItems'}" 
					    	:class="{'active': ['PaymentsItems', 'PaymentsCreate'].includes(this.$route.name)}">
					        <div class="d-flex align-items-center">
					            <span class="nav-link-icon"><span class="fa-regular fa-money-check-dollar text-700 fs--1 form-icon"></span></span>
					            <span class="nav-link-text-wrapper"><span class="nav-link-text">Платежи</span></span>
					        </div>
					    </router-link>
					</div>

					<hr/>
					<!--
					<div class="nav-item-wrapper">
					    <router-link 
					    	class="nav-link label-1" 
					    	:to="{name: 'Contacts'}" 
					    	:class="{'active': ['Contacts'].includes(this.$route.name)}">
					        <div class="d-flex align-items-center">
					            <span class="nav-link-icon"><span class="fa-brands fa-telegram text-700 fs--1 form-icon"></span></span>
					            <span class="nav-link-text-wrapper"><span class="nav-link-text">Контакты</span></span>
					        </div>
					    </router-link>
					</div>

					<hr/>-->

					<div class="nav-item-wrapper">
					    <a class="nav-link label-1" @click="logout">
					        <div class="d-flex align-items-center">
					            <span class="nav-link-icon"><span class="fa-regular fa-right-from-bracket text-700 fs--1 form-icon"></span></span>
					            <span class="nav-link-text-wrapper"><span class="nav-link-text">Выход</span></span>
					        </div>
					    </a>
					</div>

	            </ul>
	        </div>
	    </div>
	    <div class="navbar-vertical-footer">
	        <button class="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 text-start white-space-nowrap">
	            <span class="fa-regular fa-arrow-left-long-to-line text-700 fs--1 form-icon"></span>
	            <span class="fa-regular fa-arrow-right-long-to-line text-700 fs--1 form-icon"></span>
	            <span class="navbar-vertical-footer-text ms-2">Свернутый вид</span>
	        </button>
	    </div>
	</nav>
</template>

<script>
export default {
	data () {
        return {
        	
        }
    },
    methods: {
        logout() {
            localStorage.removeItem('token')
                            
            this.$axios.defaults.headers.common.Authorization = null

            this.$store.state.user.auth = false
        }
    },
    mounted () {
    	let nav_links = document.querySelectorAll('.nav-link')
    	for (var el of nav_links) {
	    	el.addEventListener('click', function(e){
	    		let aria_expanded_val = this.getAttribute('aria-expanded')
	    		if(aria_expanded_val == 'true') {
	    			this.setAttribute('aria-expanded', false)
	    		} else {
	    			this.setAttribute('aria-expanded', true)
	    		}
	    		this.classList.toggle('collapsed')
	    		if(this.nextSibling) {
	    			if(this.nextSibling.querySelector('ul.nav')) {
	    				this.nextSibling.querySelector('ul.nav').classList.toggle('show')
	    			}
	    		}
	    	})
    	}

    	document.querySelector('.navbar-vertical-toggle').addEventListener('click', function(e){
    		document.documentElement.classList.toggle('navbar-vertical-collapsed')
    	})
    }
}
</script>